import React, { FC, ReactNode } from 'react';

import CookieBanner from '../CookieBanner';
import HeadTags from '../HeadTags';
// import SalesforceChatButton from '../SalesforceChatButton';
import SegmentSnippet from '../SegmentSnippet';

import SalesforceChatButton2025 from '../SalesforceChatButton2025';

import Navbar from './navbar';
import Footer from './Footer';
import { useStore } from '../../contexts/storeContext';

const DISMISSED_COOKIE_BANNER_COOKIE_NAME = 'hideConsentCookieBanner';

interface LayoutWithHeaderAndFooterProps {
  activeMenuItem: string;
}

const LayoutWithHeaderAndFooter: FC<LayoutWithHeaderAndFooterProps> = ({
  activeMenuItem,
  children,
}) => {
  const { config, i18nUtils } = useStore();
  const isSalesforceChatVisible = config.getValue(
    'locale.salesforce-chat-enabled',
    i18nUtils.umRegion,
  );

  return (
    <div>
      <HeadTags />
      <Navbar activeMenuItem={activeMenuItem} />
      <CookieBanner cookieName={DISMISSED_COOKIE_BANNER_COOKIE_NAME} />
      <div className="page-container page-container--portal">{children}</div>
      {isSalesforceChatVisible && <SalesforceChatButton2025 />}
      {/* {isSalesforceChatVisible && <SalesforceChatButton />} */}
      <Footer />
      <SegmentSnippet />
    </div>
  );
};

export default LayoutWithHeaderAndFooter;
