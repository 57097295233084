import React, { FC } from 'react';

import CookieBanner from './CookieBanner';
import HeadTags from './HeadTags';
// import SalesforceChatButton from './SalesforceChatButton';
import SegmentSnippet from './SegmentSnippet';

import SalesforceChatButton2025 from './SalesforceChatButton2025';

import Header from './Header';
import Footer from './Footer';
import { useStore } from '../contexts/storeContext';

const DISMISSED_COOKIE_BANNER_COOKIE_NAME = 'hideConsentCookieBanner';

interface LayoutWithHeaderAndFooterProps {
  currentRoute?: string;
  showFooter?: boolean;
  showLanguageSelect?: boolean;
}

const LayoutWithHeaderAndFooter: FC<LayoutWithHeaderAndFooterProps> = ({
  children,
  currentRoute,
  showFooter = true,
  showLanguageSelect = true,
}) => {
  const { config, i18nUtils } = useStore();
  const isSalesforceChatVisible = config.getValue(
    'locale.salesforce-chat-enabled',
    i18nUtils.umRegion,
  );

  return (
    <div>
      <HeadTags />
      <Header currentRoute={currentRoute} showLanguageSelect={showLanguageSelect} />
      <CookieBanner cookieName={DISMISSED_COOKIE_BANNER_COOKIE_NAME} />
      <div className="page-container">{children}</div>
      {isSalesforceChatVisible && <SalesforceChatButton2025 />}
      {/* {isSalesforceChatVisible && <SalesforceChatButton />} */}
      {showFooter && <Footer />}
      <SegmentSnippet />
    </div>
  );
};

export default LayoutWithHeaderAndFooter;
