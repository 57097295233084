/* eslint-disable no-underscore-dangle */
import React from 'react';
import { IReactionDisposer, observable, reaction } from 'mobx';
import { observer, inject } from 'mobx-react';
import autobind from 'autobind-decorator';
import getConfig from 'next/config';

import { browserOnly } from './EnvSplit';

const TIMEOUT_DELAY = 2000;

/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    liveagent: any;
    liveAgentDeployment: any;
    _laq: any;
    embeddedservice_bootstrap: any;
  }
}
/* eslint-enable @typescript-eslint/no-explicit-any */

const { publicRuntimeConfig } = getConfig();
const { NODE_ENV } = publicRuntimeConfig;

const CHAT_CONFIG = {
  staging: {
    CHAT_SCRIPT_URL: 'https://urbanco--partialcpy.sandbox.my.site.com/ESWMessagingHeroWebsite1736361168688/assets/js/bootstrap.min.js',
    CHAT_ENVIRONMENT_ID: '00DUE000001FwG7',
    CHAT_PLATFORM_ID: 'Messaging_Hero_Website2',
    CHAT_DEPLOYMENT_URL: 'https://urbanco--partialcpy.sandbox.my.site.com/ESWMessagingHeroWebsite1736361168688',
    CHAT_SRC_URL: 'https://urbanco--partialcpy.sandbox.my.salesforce-scrt.com',
  },
  production: {
    CHAT_SCRIPT_URL: 'https://urbanco.my.site.com/ESWHeroWebsite1738087114893/assets/js/bootstrap.min.js',
    CHAT_ENVIRONMENT_ID: '00D0N000000gJ18',
    CHAT_PLATFORM_ID: 'Hero_Website',
    CHAT_DEPLOYMENT_URL: 'https://urbanco.my.site.com/ESWHeroWebsite1738087114893',
    CHAT_SRC_URL: 'https://urbanco.my.salesforce-scrt.com',
  },
};

const CHAT_ENVIRONMENT_CONFIG = NODE_ENV === 'production' ? CHAT_CONFIG.production : CHAT_CONFIG.staging;

interface ChatButtonProps {
  config?: {
    getValue: Function;
  };
  i18nUtils?: {
    umRegion: string;
  };
  initialLoadComplete?: boolean;
  tracker?: {
    track: Function;
  };
  screen?: {
    isDesktop: boolean;
  };
  session?: {
    user: {
      id: number;
      name: string;
    };
  };
}

@browserOnly
@inject(({ store: { config, i18nUtils, initialLoadComplete, screen, session, tracker } }) => ({
  config,
  i18nUtils,
  initialLoadComplete,
  screen,
  session,
  tracker,
}))
@observer
class ChatButton extends React.Component<ChatButtonProps, null> {
  listeners: IReactionDisposer[];
  timeout: NodeJS.Timeout;

  @observable hasStartedChat = false;
  @observable isChatOpen = false;
  @observable isUserChat = false;
  @observable showChatOfflinePopup = false;

  componentDidMount(): void {
    if (!window._laq) {
      window._laq = [];
    }

    const { session } = this.props;
    const { user } = session;

    /* eslint-disable react/destructuring-assignment */
    this.listeners = [
      reaction(
        () => session && session.user && session.user.id,
        id => {
          this.loadChat(id);
        },
        {
          name: 'Create chat session when user signs in',
        },
      ),
      reaction(
        () => this.props.initialLoadComplete,
        initialLoadComplete => {
          if (initialLoadComplete) {
            const userId = (user && user.id) || undefined;

            this.timeout = setTimeout(() => {
              this.loadChat(userId);
            }, TIMEOUT_DELAY);
          }
        },
        {
          fireImmediately: true,
          name: 'Check if content has finished loading',
        },
      ),
    ];
    /* eslint-enable react/destructuring-assignment */
  }

  /*
   *  This function adds a new script from url parameter to the document head and allows a callback
   *  to be called once the script loads
   */
  loadScript(url: string, callback: () => void): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const script: any = document.createElement('script');
    script.type = 'text/javascript';

    if (script.readyState) {
      //IE
      script.onreadystatechange = (): void => {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      //Others
      script.onload = (): void => {
        callback();
      };
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  /*
   *  Reload the Salesforce Live Agent
   */
  @autobind
  loadChat(userId: number): void {
    // We need to delete all the liveagent objects
    delete window.liveagent;
    delete window.liveAgentDeployment;

    // This mock-agent suppresses errors caused by pending avail-checks
    window.liveagent = { _: { handlePing: (): void => {} } }; // eslint-disable-line

    this.loadScript(CHAT_ENVIRONMENT_CONFIG.CHAT_SCRIPT_URL, () => {
      try {
        window.addEventListener('onEmbeddedMessagingReady', () => {
          const pageURL = window.location.href; // Use this for the full URL
          window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({
            Platform: 'HERO WEBSITE',
            UMUserID: `${userId}`,
            Location: pageURL,
          });
          // Lets populate the Images used for the Messaging Chat branding with our static resources
          const brandings = window.embeddedservice_bootstrap.settings.embeddedServiceConfig.branding;
          console.log('Brandings:', brandings);
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < brandings.length; i++) {
            console.log('Branding looping');
            if (brandings[i].n === 'logoImage') {
              // eslint-disable-next-line id-length
              brandings[i].v = 'https://res.cloudinary.com/huxr6hrje/image/upload/v1734957989/chat-2025/UM_Chat_Logo.png';
              console.log('LogoImage updated');
            } else if (brandings[i].n === 'avatarImage') {
              // eslint-disable-next-line id-length
              brandings[i].v = 'https://res.cloudinary.com/huxr6hrje/image/upload/v1734957989/chat-2025/UM_Agent_Neutral.png';
              console.log('AvatarImage updated');
            } else if (brandings[i].n === 'botImage') {
              // eslint-disable-next-line id-length
              brandings[i].v = 'https://res.cloudinary.com/huxr6hrje/image/upload/v1738687560/chat-2025/prod/botimage2.png';
              console.log('BotImage updated');
            }
          }
        });

        window.embeddedservice_bootstrap.settings.language = 'en_US'; // For example, enter 'en' or 'en-US'

        window.embeddedservice_bootstrap.init(
          CHAT_ENVIRONMENT_CONFIG.CHAT_ENVIRONMENT_ID,
          CHAT_ENVIRONMENT_CONFIG.CHAT_PLATFORM_ID,
          CHAT_ENVIRONMENT_CONFIG.CHAT_DEPLOYMENT_URL,
          {
            scrt2URL: CHAT_ENVIRONMENT_CONFIG.CHAT_SRC_URL,
          },
        );
      } catch (err) {
        console.error('Error loading Embedded Messaging: ', err);
      }
    });
  }

  render(): JSX.Element {
    return (
      <div />
    );
  }
}

export default ChatButton;
